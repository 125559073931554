import Cookies from 'universal-cookie'
import { PersistentCookiesKeys } from '@app/enums/persistentCookiesKeys'
import LoginV1Service from "@app/services/ApplicationV1Bridge";

export const clearPersistentData = async () => {
  const cookies = new Cookies()

  localStorage.clear()
  sessionStorage.clear()
  await LoginV1Service.cancelSessionCookie(process.env.REACT_APP_V1_COOKIE_DOMAIN ?? "")
  cookies.remove(PersistentCookiesKeys.LbImp, {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
  })
  cookies.remove(PersistentCookiesKeys.CognitoSession, {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
  })
  cookies.remove('fbm_939660826071336', {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
  })
  cookies.remove('fbsr_939660826071336', {
    path: '/',
    domain: process.env.REACT_APP_V1_COOKIE_DOMAIN,
  })
}
